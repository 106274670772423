<!--
 * @Author: zx
 * @Date: 2022-09-22 14:57:41
 * @LastEditTime: 2022-09-22 15:33:30
 * @Description: 
 * @LastEditors: zx
-->
<template>
  <div class="add-store">
    <el-form
      slot="content"
      ref="addForm"
      :rules="rules"
      :model="addForm"
      label-width="80px"
    >
      <el-form-item label="对象标识" prop="code">
        <el-input v-model="addForm.code"></el-input>
      </el-form-item>
      <el-form-item label="中文名称" prop="name">
        <el-input v-model="addForm.name"></el-input>
      </el-form-item>
      <el-form-item label="对象备注">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="addForm.remark"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="需要流程">
        <el-switch v-model="addForm.needFlow" />
      </el-form-item>
      <el-form-item
        label="需要用户"
        v-if="!addForm.needFlow"
      >
        <el-switch v-model="addForm.needUser" />
      </el-form-item>
      <el-form-item
        label="组织信息"
        v-if="!addForm.needFlow"
      >
        <el-switch v-model="addForm.needArchi" />
      </el-form-item>
    </el-form>
    <div class="add-button">
      <el-button type="default" size="mini" @click="cancel">取消</el-button>
      <el-button v-loading="loading" type="primary" size="mini" @click="sure">新增</el-button>
    </div>
  </div>
</template>
<script>
  import object from '@/api/apiSaasStore'
  export default {
    components:{},
    props:{
    },
    data() {
      return {
        loading: false,
        addForm: {
          name: "",
          code: "",
          remark: "",
          needFlow: false,
          needArchi: false,
          needUser: false,
          enabled: 1,
        },
        rules: {
          code: [
            { required: true, message: "请输入", trigger: "blur" },
            {
              min: 1,
              max: 30,
              message: "code的长度在1到30个字符之间",
              trigger: "blur",
            },
            {
              pattern: /^[A-Za-z0-9_]+$/,
              message: "code只能是字母和数字以及下划线",
            },
          ],
          name: [{ required: true, message: "请输入", trigger: "blur" }],
        },
      }
    },
    mounted() {
    },
    methods:{
      cancel() {
        this.$emit('closeDrawer', false)
      },
      sure() {
        this.$refs.addForm.validate((valid) => {
          if (valid) {
            this.loading = true
            object.addOne(this.addForm).then((res) => {
              this.loading = false
              if (res.data.code == 200) {
                this.$emit('closeDrawer', true)
                this.$refs.addForm.resetFields();
              }
            });
          } else {
            this.$refs.elmodal.loading = false;
          }
        });
      },
    }
  }
</script>
<style lang='less' scoped>
  .add-store {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    .add-button {
      display: flex;
      justify-content: flex-end;
    }
  }
</style>
/*
 * @Author: zx
 * @Date: 2022-09-22 14:23:28
 * @LastEditTime: 2022-09-23 17:57:55
 * @Description:
 * @LastEditors: zx
 */
import request from '@/apis/http/saasRequest.js'
const baseStr = 'api/saas/metadata/'
const objects = {
  getAllList(params) {
    return request({
      url: baseStr + 'objects',
      method: 'GET',
      params
    })
  },
  addOne(data) {
    return request({
      url: baseStr + 'objects',
      method: 'POST',
      data
    })
  },
  editOne(uuid, name = '', remark = '', extra = '') {
    let data = {
      name: name,
      remark: remark
    }
    if (extra) {
      data.extra = extra
    }
    return request({
      url: baseStr + 'objects/' + uuid,
      method: 'put',
      data
    })
  },
  /* 数仓etl请求相关 */
  etlList(page=1,size=10,name,object_uuid){
    return request({
      url : "/api/etl",
      method:"get",
      params:{
        page,size,name,object_uuid
      }
    })
  },
  /**
   * @description: ETL 新增
   * @param {String} name
   * @param {Array} cells
   */  
   etlAdd(name,cells, object_uuid){
    return request({
      url:"/api/etl",
      method:"post",
      data:{
        name,cells,object_uuid
      }
    })
  },
  /**
   * @description: ETL 编辑
   * @param {String} id
   * @param {String} name
   * @param {Array} cells
   */  
  etlEdit(id,name,cells,object_uuid){
    return request({
      url:"/api/etl/" + id,
      method:"put",
      data:{
        name,cells,object_uuid
      }
    })
  },
  /**
   * @description: ETL 删除
   * @param {String} id
   */  
  etlDelete(id){
    return request({
      url:"/api/etl/" + id,
      method:"delete"
    })
  },
  /**
   * @description: etl 详情
   * @param {String} id
   */  
  etlInfo(id){
    return request({
      url:"/api/etl/" + id,
      method:"get"
    })
  },
}
export default objects
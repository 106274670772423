<!--
 * @Author: zx
 * @Date: 2022-09-21 10:23:35
 * @LastEditTime: 2022-12-30 15:25:07
 * @Description:
 * @LastEditors: zx
-->
<template>
  <div class="data-store">
    <div class="data-search">
      <div class='search-input-main'>
        <div class="search-input">名称:
          <el-input v-model="form.name" placeholder="请输入搜索名称" @input="searchList"> </el-input>
        </div>
        <div class="search-input">uuid:
          <el-input v-model="form.uuid" placeholder="请输入搜索uuid" @input="searchList"> </el-input>
        </div>
      </div>
      <div class="data-search-add">
        <el-button type="primary" size="mini" @click="addStore">新增</el-button>
      </div>
    </div>
    <div class="data-list">
      <el-table
        v-loading="loading"
        ref="viewTable"
        :data="tableList"
        :border="true"
        center
        style="width: 100%;"
        height="100%"
        highlight-current-row
      >
        <el-table-column label="序号" type="index" width="120"></el-table-column>
        <el-table-column label="名称" prop="name">
          <template slot-scope="{ row }">
            <el-input
              class="edit-cell"
              v-if="showEdit[row.id]"
              v-model="row.name"
            />
            <span v-else>
              {{ row.name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="标识" prop="code"></el-table-column>
        <el-table-column label="uuid" prop="uuid"></el-table-column>
        <el-table-column label="说明" prop="remark">
          <template slot-scope="{ row }">
            <el-input
              class="edit-cell"
              v-if="showEdit[row.id]"
              v-model="row.remark"
            />
            <span v-else>
              {{ row.remark }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="right" width="240">
          <template slot-scope="scope">
            <span class="operation-primary" @click="detailClick(scope.row)">
              详情
            </span>
            <span
              class="operation-primary"
              v-if="!showEdit[scope.row.id]"
              @click="handleEdit(scope.$index, scope.row)"
            >
              编辑
            </span>
            <span
              class="operation-primary"
              v-if="showEdit[scope.row.id]"
              @click="editField(scope.row, scope.$index)"
              >保存</span
            >
            <span
              class="operation-primary"
              v-if="showEdit[scope.row.id]"
              @click="handelCancel(scope.$index, scope.row)"
              >取消</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination
        class="pagination"
        @size-change="onSize"
        @current-change="onPage"
        :current-page="+pager.page"
        :page-sizes="[15, 20, 50, 100, 200]"
        :page-size="+pager.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="+pager.total"
      >
      </el-pagination>
    </div>
    <el-drawer
      :title="drawerTitle"
      :visible.sync="showAddDrawer"
      :append-to-body="true"
      :direction="'rtl'"
      size="40%"
    >
      <AddStore @closeDrawer="closeDrawer"></AddStore>
    </el-drawer>
  </div>
</template>
<script>
  import object from '@/api/apiSaasStore'
  import AddStore from './components/AddStore'
  import { mapState } from 'vuex';
  export default {
    components:{
      AddStore
    },
    props:{
    },
    data() {
      return {
        tableList: [],
        form: {
          name: '',
          uuid:''
        },
        pager: {
          page: 1,
          size: 15,
          total: 0
        },
        drawerTitle: '',
        showAddDrawer: false,
        loading: false,
        showEdit: {}, //显示编辑框
      }
    },
    computed: {
      ...mapState(['saasCurrentMenu'])
    },
    created() {
      this.getList()
    },
    methods:{
      /**
      * @desc: 分页数量
      * @param {Number} size
      */
      onSize(size) {
        this.pager.size = size;
        this.pager.page = 1;
        this.getList();
      },
      /**
       * @desc: 切换页码
       * @param {Number} page
       */
      onPage(page) {
        this.pager.page = page;
        this.getList();
      },
      /* 请求列表数据 */
      getList() {
        const params = {
          page: this.pager.page,
          size: this.pager.size,
        }
        if(this.form.name) {
          params.name = this.form.name
        }
        if(this.form.uuid){
          params.uuid = this.form.uuid
        }
        this.loading = true
        object.getAllList(params).then((res) => {
          this.loading = false
          if (res?.status === 200 && res.data?.code === 200) {
            this.pager.total = res.data.data.total
            this.tableList = res.data.data.data
          }
        })
      },
      detailClick(row) {
        const data = JSON.parse(JSON.stringify(row))
        data.route = '/dwh/objects/detail'
        data.path = '/dwh/objects/detail'
        this.$router.push({
          path: "/dwh/objects/detail",
          query: {
            obuuid: data.uuid,
            obname: data.name,
            type: 'field',
            is_virtual: data.is_virtual,
            enabled: data.enabled,
            obcode: data.code,
          },
        });
      },
      addStore() {
        this.drawerTitle = '新增对象'
        this.showAddDrawer = true
      },
      closeDrawer(boo) {
        this.showAddDrawer = false
        if (boo) {
          this.pager.page = 1
          this.getList()
        }
      },
      handleEdit(index, row) {
        if (row.is_virtual == 1) {
          this.$alert("表单对象不允许编辑！", "提示");
          return;
        }
        this.$set(this.showEdit, row.id, true);
      },
      handelCancel(index, row) {
        //取消编辑
        this.$set(this.showEdit, row.id, false);
        this.getList()
      },
      editField(data) {
        this.handelCancel(0, data)
        object.editOne(data.uuid, data.name, data.remark).then(() => {
          this.getList()
        });
      },
      /* 搜索列表名称 */
      searchList() {
        this.getList()
      }
    }
  }
</script>
<style lang='less' scoped>
  .data-list {
    :deep(.el-table__body-wrapper) {
      overflow-y: auto;
    }
  }
  .data-store {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 16px;
    box-sizing: border-box;
    .data-search {
      width: 100%;
      padding-right: 16px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .search-input-main{
        width: 600px;
        display:flex;
        margin-right: 24px;
        box-sizing: border-box;
        align-items: center;
        .search-input{
          width: 300px;
          display:flex;
          margin-right: 24px;
          box-sizing: border-box;
          align-items: center;
          div{
            flex:1;
            padding-left: 5px;
            box-sizing: border-box;
          }
        }

      }
    }
    .data-list {
      flex: 1;
      overflow: hidden;
      margin-top: 16px;
      box-sizing: border-box;
      border-bottom: 1px solid #EBEEF5;
    }
    .pagination-container {
      display: flex;
      justify-content: flex-start;
      .pagination {
        padding-top: 8px;
      }
    }
  }
</style>
